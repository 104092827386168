import React from 'react'

import Layout from '../components/layout'
import { Link } from 'gatsby'
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import { JsonLd } from '../components/JsonLd'

import chambre_image_2 from '../images/chambre/votes_54.png'
import agri_map from '../images/artificialisation_sols/agri_map.png'

class ProjectInfos extends React.Component {
  render() {
    let project_title;
    let project_image;
    let project_buttons;
    if ((this.props.link_int === "") && (this.props.link_ext === "")) {
      project_title = <h2>{this.props.title}</h2>;
      project_image = <img className="image fit" src={this.props.image} alt={this.props.alt}/>
      project_buttons =  <ul className="actions special"></ul>;
    }
    else if (this.props.link_int === "") {
      project_title = <h2><a href={this.props.link_ext} target="_blank" rel="noopener noreferrer">{this.props.title}</a></h2>
      project_image =  <a className="image fit" href={this.props.link_ext} target="_blank" rel="noopener noreferrer">
                         <img src={this.props.image} alt={this.props.alt}/>
                       </a>
      project_buttons =  <ul className="actions special">
                            <li><a className="button" href={this.props.link_ext} target="_blank" rel="noopener noreferrer">
                          Voir</a></li>
                          </ul>;
    } else if (this.props.link_ext === "") {
      project_title = <h2><Link to={this.props.link_int}>{this.props.title}</Link></h2>
      project_image =  <Link className="image fit" to={this.props.link_int}>
                         <img src={this.props.image} alt={this.props.alt}/>
                       </Link>
      project_buttons =  <ul className="actions special">
                            <li><Link to={this.props.link_int} className="button">Le projet</Link></li>
                          </ul>;
    } else {
      project_title = <h2><Link to={this.props.link_int}>{this.props.title}</Link></h2>
      project_image =  <Link className="image fit" to={this.props.link_int}>
                         <img src={this.props.image} alt={this.props.alt}/>
                       </Link>
      project_buttons =  <ul className="actions special_duo">
                          <li><Link to={this.props.link_int} className="button">Le projet</Link></li>
                          <li><a className="button" href={this.props.link_ext} target="_blank" rel="noopener noreferrer">
                          Voir</a></li>
                          </ul>;
    }                
    return (
      <article>
        <header>
          <span className="date">{this.props.date}</span>
          {project_title}
        </header>
        {project_image}
        <p>{this.props.description}</p>
        <p><em>{this.props.additional_infos}</em></p>
          {project_buttons}
      </article>
    )
  }
}
class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
      <Seo title="Accueil" />
      <Helmet>
        <JsonLd>
        {{
            "@context": "http://schema.org",
            "@type": "Blog",
            "author": {
                "@type": "Person",
                "name": "Matthieu Genicot",
                "url": "http://www.pointdevuedata.be"
            },
            "blogPost": [
                {
                    "@type": "BlogPosting",
                    "headline": "Que votent nos députés ?",
                    "keywords": "vote, députés, chambre, Belgique",
                    "url": "https://www.pointdevuedata.be/votes-deputes/",
                },
              ]
        }}
        </JsonLd>
        <meta name="msvalidate.01" content="AD14A5CE0FF0D0433D18BB6966C5EDEA" />
      </Helmet>
      <div id="main">
          <article className="post">
            <section className="bigger_margin">
            <p>
            <strong>Point de vue Data</strong>, c'est mon envie d'allier passion du data et
            engagement pour des lendemains mieux informés et plus transparents.
            <br/>
            <strong>Point de vue Data</strong>, c'est aussi mon souhait de travailler avec des journalistes,
            partis politiques, organisations publiques ou sociétés privées, qui ont des données et des messages à faire passer.
            <br/>
            <strong>Point de vue Data</strong>, c'est enfin et surtout mille possibilités pour
            créer, explorer et présenter des données qui vous semblent pertinentes et
            qui ont des choses à raconter.
            </p>
            </section>
            <section>
            <p>
            Mes derniers projets sont repris ci-dessous, et des projets qui m'ont inspiré sont également à découvrir
            dans <em><Link to="/vu-ailleurs/">VU AILLEURS</Link></em>.
            </p>
            </section>
            <section>
            Vous avez un projet&nbsp;?&nbsp; Pour collaborer, discuter d'une idée ou juste me laisser un message, 
            allez faire un tour sur <em><Link to="/me-contacter/">ME CONTACTER</Link></em>.
            </section>
          </article>

          <section className="posts" id="derniers_projets">

          <ProjectInfos
              date = "Mai 2021"
              link_int = ""
              link_ext = "/declin-agricole/"
              title = {["La perte de nos", <br/>, " terres agricoles"]}
              image = {agri_map}
              alt = "Champs agricole"
              description = {
                <>
                Dans le contexte actuel de crise environnementale, comment se portent nos terres agricoles&nbsp;?
                Un contenu <b>scrollitelling</b> pour faire le point sur la situation.
                </>
              }
              additional_infos = "Projet pilote"
            />
            <ProjectInfos
              date = "En cours"
              link_int = "/votes-deputes/"
              link_ext = ""
              title = {["Que votent nos", <br/>, " députés ?"]}
              image = {chambre_image_2}
              alt = "Tableau des votes des députés belges"
              description = {
                <>
                Et si on se souciait moins de ce que nos députés promettent et
                plus de ce qu'ils votent vraiment&nbsp;? 
                <b> Extraction</b> des milliers de votes de nos députés et développement d'un outil <b>interactif</b> pour permettre à chacun d'y accéder.
                </>
              }
              additional_infos = "Plus à venir"
            />
            <ProjectInfos
              date = "En cours"
              link_int = ""
              link_ext = ""
              title = {["Analyse des", <br/>, " médias belges"]}
              image = ''
              alt = "pas encore d'image"
              description = {
                <>
                Quelle est la représentation des femmes dans les divers médias belges&nbsp;?
                Quelle place est donnée à chaque parti&nbsp;?
                Quel sujet est traité différemment selon le journal&nbsp;?
                <br/>
                Une foule de possibilités pour décortiquer le contenu des différents médias
                grâce à l'<b>IA</b>.
                </>
              }
              additional_infos = "Plus à venir"
            />
            <article>
              <header>
                <span className="date">Projet futur</span>
                <h2>Un projet<br/> en vue&nbsp;?</h2>
              </header>
              <p>Ces projets vous donnent des idées et vous cherchez quelqu'un pour en discuter ou les réaliser&nbsp;?
              </p>
              <ul className="actions special">
                <li><Link className="button" to="/me-contacter/">Me contacter</Link></li>
              </ul>
              <blockquote>
              Grand amateur de thé en hiver, et de bières en été,
              les chances que je refuse de discuter d'un projet ou d'une idée
              autour de l'un ou l'autre sont infimes&hellip;
              </blockquote>
            </article>
          </section>

      </div>
      </Layout>
    )
  }
}
export default IndexPage